import React from 'react';

import '../styles/PrintingImaging.css'

const PrintingImaging = () => {
    return (
        <div className="printing-imaging-container">

            <h2>Printing & Imaging Solutions</h2>

            <h3>Overview</h3>
            <p>
                The realm of printing and imaging has undergone transformative changes with the infusion of digital technology. Today, it's more than just printing a document; it's about creating impressions that last. PhiGrid's Printing & Imaging Solutions bring state-of-the-art technology and innovation to your fingertips, ensuring top-notch quality, efficiency, and sustainability.
            </p>

            <h3>Why Embrace PhiGrid's Printing & Imaging Solutions?</h3>
            <ul>
                <li><strong>High-Quality Output:</strong> Our solutions ensure vibrant, clear, and sharp outputs, giving life to your ideas and designs.</li>
                <li><strong>Cost-Effective:</strong> Enjoy the luxury of high-quality printing without burning a hole in your pocket. Our solutions are designed to be cost-effective without compromising on quality.</li>
                <li><strong>Environmentally Friendly:</strong> We're committed to sustainability. Our solutions are designed to reduce waste and energy consumption.</li>
                <li><strong>Advanced Security:</strong> Keep your confidential prints secure. Our printing solutions come with advanced security features, ensuring only authorized personnel can access and print documents.</li>
                <li><strong>Scalable Solutions:</strong> Whether you're a small business or a global enterprise, our solutions scale according to your needs, ensuring you always have the right fit.</li>
            </ul>

            <h3>Our Offerings</h3>
            <ul>
                <li><strong>Multifunction Printers (MFPs):</strong> Experience the convenience of printing, scanning, and copying all in one device.</li>
                <li><strong>Large Format Printers:</strong> Bring your big ideas to life with our range of large format printers suitable for banners, posters, and architectural drawings.</li>
                <li><strong>3D Printing Solutions:</strong> Step into the future of manufacturing and prototyping with our advanced 3D printing solutions.</li>
                <li><strong>Document Management Systems:</strong> Organize, store, and retrieve your digital documents with ease using our holistic document management solutions.</li>
                <li><strong>Managed Print Services:</strong> Let us handle the complexities of your printing needs, from device maintenance to ink and toner supply.</li>
            </ul>

            {/* <h3>Client Testimonials</h3>
            <blockquote>
                "With PhiGrid's printing solutions, every print feels like a masterpiece. The clarity, the vibrancy, it's just unparalleled." - <cite>Eliza Turner, DesignCraft Studios.</cite>
            </blockquote>
            <blockquote>
                "Their Managed Print Services have been a boon for us. It's hassle-free, efficient, and always up to the mark." - <cite>Rohan Das, FutureScape Tech.</cite>
            </blockquote> */}

            <h3>Pushing Boundaries, One Print at a Time</h3>
            <p>Experience printing like never before. Dive deep into a world where each print tells a story, where technology meets art, all with PhiGrid's Printing & Imaging Solutions.</p>
            <button>Contact Us</button>

        </div>
    );
}

export default PrintingImaging;