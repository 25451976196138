import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import React from 'react';
import Slider from "react-slick";
import { useNavigate } from 'react-router-dom';

import cloudComputing from '../assets/cloud_computing.png';
import endUserComputing from '../assets/end_user_computing.jpg';
import networkingSolutions from '../assets/networking_solutions.jpg';
import securitySolutions from '../assets/security_solutions.jpg';
import enterpriseComputing from '../assets/enterprise_computing.png';
import datacenter from '../assets/datacenter.png';

const ImageSlider = () => {
    const navigate = useNavigate();

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,  // Sets autoplay speed to 3 seconds
    };

    // Setting a consistent width and height for all images for uniformity.
    const imgStyle = {
        width: '100%', 
        height: '400px',
        objectFit: 'cover' // This makes sure the image content is resized to fit the container.
    };

    return (
        <Slider {...settings}>
            <div onClick={() => navigate('/solutions/cloudcomputing')}>
                <img src={cloudComputing} alt="Cloud Computing" style={imgStyle} />
            </div>
            <div onClick={() => navigate('/solutions/endusercomputing')}>
                <img src={endUserComputing} alt="End User Computing" style={imgStyle} />
            </div>
            <div onClick={() => navigate('/solutions/networking')}>
                <img src={networkingSolutions} alt="Networking Solutions" style={imgStyle} />
            </div>
            <div onClick={() => navigate('/solutions/security')}>
                <img src={securitySolutions} alt="Security Solutions" style={imgStyle} />
            </div>
            <div onClick={() => navigate('/solutions/enterprisecomputing')}>
                <img src={enterpriseComputing} alt="Enterprise Computing" style={imgStyle} />
            </div>
            <div onClick={() => navigate('/solutions/datacenter')}>
                <img src={datacenter} alt="Data Center Solutions" style={imgStyle} />
            </div>
        </Slider>
    );
}

export default ImageSlider;