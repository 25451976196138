import React from 'react'
import ImageSlider from '../components/ImageSlider'
import About from '../components/About'
import ServiceHighlights from '../components/ServiceHighlights'
import Testimonials from '../components/Testimonials'
import FAQSection from '../components/FAQSection'

const Home = () => {
  return (
    <div>
      <ImageSlider />
      <About />
      <ServiceHighlights />
      {/* <Testimonials /> */}
      <FAQSection />
    </div>
  )
}

export default Home