import React, { useEffect, useState } from 'react';
import '../styles/EnterpriseComputing.css'
import enterpriseComputingImage from '../assets/enterprise_computing.png';

const EnterpriseComputing = () => {
    const [isLandscape, setIsLandscape] = useState(window.matchMedia("(orientation: landscape)").matches);
    const [isPortrait, setIsPortrait] = useState(window.matchMedia("(orientation: portrait)").matches);

    useEffect(() => {
        const handleOrientationChange = () => {
            setIsLandscape(window.matchMedia("(orientation: landscape)").matches);
            setIsPortrait(window.matchMedia("(orientation: portrait)").matches);
        };

        window.addEventListener("orientationchange", handleOrientationChange);

        return () => {
            window.removeEventListener("orientationchange", handleOrientationChange);
        };
    }, []);

    return (
        <div className="enterprise-computing-container">
            {isLandscape && <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ flex: '0.5', marginRight: '20px' }}>
                    <h2>Enterprise Computing Solutions</h2>
                    <h3>Overview</h3>
                    <p>In the age of data-driven decision-making and unparalleled growth in technological advancements, Enterprise Computing stands as the backbone for large-scale organizations. It ensures optimal performance, scalability, and reliability for mission-critical applications. At PhiGrid, we understand the intricacies of enterprise requirements and offer solutions that lay the foundation for your organization's technological success.</p>
                </div>

                <div style={{ flex: '0.45' }}>
                    <img src={enterpriseComputingImage} alt="Enterprise Computing" style={{ maxWidth: '100%', height: 'auto' }} />
                </div>
            </div>}

            {isPortrait && <div>
                <div style={{ flex: '0.5', marginRight: '20px' }}>
                    <h2>Enterprise Computing Solutions</h2>
                    <h3>Overview</h3>
                    <p>In the age of data-driven decision-making and unparalleled growth in technological advancements, Enterprise Computing stands as the backbone for large-scale organizations. It ensures optimal performance, scalability, and reliability for mission-critical applications. At PhiGrid, we understand the intricacies of enterprise requirements and offer solutions that lay the foundation for your organization's technological success.</p>
                </div>

                <div style={{ flex: '0.45' }}>
                    <img src={enterpriseComputingImage} alt="Enterprise Computing" style={{ maxWidth: '100%', height: 'auto' }} />
                </div>
            </div>}

            <h3>Why PhiGrid's Enterprise Computing Solutions?</h3>
            <ul>
                <li><strong>Scalable Architecture:</strong> As your business grows, so should your IT infrastructure. Our solutions seamlessly scale to meet the growing demands of your organization.</li>
                <li><strong>High Availability:</strong> Downtime is costly. We prioritize systems that ensure maximum uptime, minimizing interruptions and ensuring business continuity.</li>
                <li><strong>Integrated Systems:</strong> Our solutions integrate various facets of IT, from storage to processing, ensuring a unified, efficient, and streamlined IT ecosystem.</li>
                <li><strong>Advanced Security:</strong> In the enterprise world, data breaches can be catastrophic. Our enterprise solutions come equipped with robust security features to guard against potential threats.</li>
                <li><strong>Cost-effective:</strong> While offering cutting-edge solutions, we understand the importance of cost. Our solutions ensure high ROI, blending performance with affordability.</li>
            </ul>

            <h3>Key Offerings</h3>
            <ul>
                <li><strong>Server Solutions:</strong> Modernize your data centers with the latest in server technology, optimized for performance and energy efficiency.</li>
                <li><strong>Storage Solutions:</strong> Handle the ever-growing data with storage solutions that are reliable, fast, and scalable.</li>
                <li><strong>Data Analytics & Processing:</strong> Harness the power of data with tools and systems designed to process and analyze large datasets efficiently.</li>
                <li><strong>Virtualization:</strong> Optimize your IT resources, reduce hardware costs, and improve application performance with modern virtualization techniques.</li>
                <li><strong>Networking Solutions:</strong> Seamless integration and communication between your IT systems ensure optimal performance and resource utilization.</li>
            </ul>

            {/* <h3>Testimonials</h3>
            <blockquote>
                "Shifting to PhiGrid's Enterprise Computing solutions was a game-changer. We've seen notable improvements in system performance and efficiency." - <cite>Michael Lang, BigTech Co.</cite>
            </blockquote>
            <blockquote>
                "The team at PhiGrid provided a solution tailored to our needs. It's evident they understand the enterprise domain deeply." - <cite>Rebecca Nguyen, FutureTech Enterprises.</cite>
            </blockquote> */}

            <h3>Future-proof Your Business</h3>
            <p>In the rapidly evolving world of technology, ensure your business remains at the forefront. Equip yourself with Enterprise Computing solutions by PhiGrid.</p>
            <button>Contact Us</button>

        </div>
    );
}

export default EnterpriseComputing;