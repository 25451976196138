import React, { useState } from 'react';
import '../styles/FAQSection.css';

const FAQSection = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const faqData = [
        {
            question: "What services does PhiGrid offer?",
            answer: "PhiGrid offers a range of IT solutions including Cloud Computing, End User Computing, Networking Solutions, Security Solutions, Enterprise Computing, and Data Center Solutions."
        },
        {
            question: "How can PhiGrid enhance my business security?",
            answer: "PhiGrid's Security Solutions cover a wide range of features from firewall and intrusion prevention to data protection & encryption. We prioritize your network's security ensuring data protection at all times."
        },
        {
            question: "Can I customize the IT solutions for my specific needs?",
            answer: "Absolutely. At PhiGrid, we understand that each business has unique requirements. Our team will work closely with you to tailor the solutions according to your business needs."
        },
        {
            question: "How does the support process work?",
            answer: "We have a dedicated support team available 24/7. You can reach out to us via email, phone, or our support portal."
        }
    ];

    return (
        <div className="faq-container">
            <h2 className="faq-title">Frequently Asked Questions</h2>
            <div className="faq-list">
                {faqData.map((faq, index) => (
                    <div key={index} className="faq-item">
                        <div
                            className="faq-question"
                            onClick={() => activeIndex === index ? setActiveIndex(null) : setActiveIndex(index)}
                        >
                            <strong>{faq.question}</strong>
                        </div>
                        {activeIndex === index && (
                            <div className="faq-answer">
                                {faq.answer}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default FAQSection;