import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

import logo2 from '../assets/phigrid3.png';
import logo3 from '../assets/phigrid4.png'
import '../styles/Navbar.css';

const Navbar = () => {
    const [isLandscape, setIsLandscape] = useState(window.matchMedia('(orientation: landscape)').matches);
    const [isPortrait, setIsPortrait] = useState(window.matchMedia('(orientation: portrait)').matches);

    useEffect(() => {
        const handleOrientationChange = () => {
            setIsLandscape(window.matchMedia('(orientation: landscape)').matches);
            setIsPortrait(window.matchMedia('(orientation: portrait)').matches);
        };

        window.addEventListener('orientationchange', handleOrientationChange);

        return () => {
            window.removeEventListener('orientationchange', handleOrientationChange);
        };
    }, []);

    return (
        <div className="navbar">
            {isLandscape && (
                <div className="navbar-logo">
                    <Link to="/">
                        <img src={logo2} alt="PhiGrid Logo" className="logo-img" />
                    </Link>
                </div>
            )}
            
            <div className="menu-container">
                {isPortrait && (
                    <div className="navbar-logo-m">
                        <Link to="/">
                            <img src={logo3} alt="PhiGrid Logo" className="logo-img-m" />
                        </Link>
                    </div>
                )}
                <div className="dropdown">
                    <button className="dropbtn">
                        Solutions <FontAwesomeIcon icon={faCaretDown} />
                    </button>
                    <div className="dropdown-content">
                        <Link to="/solutions/cloudcomputing">Cloud Computing</Link>
                        <Link to="/solutions/endusercomputing">End User Computing</Link>
                        <Link to="/solutions/networking">Networking Solutions</Link>
                        <Link to="/solutions/security">Security Solutions</Link>
                        <Link to="/solutions/enterprisecomputing">Enterprise Computing</Link>
                        <Link to="/solutions/datacenter">Data Center Solutions</Link>
                        <Link to="/solutions/printingimaging">Printing & Imaging</Link>
                    </div>
                </div>
                
                <Link to="/careers">Careers</Link>
                <Link to="/culture">Culture</Link>
                <Link to="/about">About us</Link>
            </div>
        </div>
    );
}

export default Navbar;