import React from 'react';
import '../styles/ServiceHighlights.css';

const ServiceHighlights = () => {
    return (
        <div className="service-highlights-container">
            <h2 className="service-highlights-title">Our Service Highlights</h2>
            <div className="service-highlights-list">
                {/* Service 1: Cloud Computing */}
                <div className="service-highlight-item">
                    <h3 className="service-highlight-heading">Cloud Computing</h3>
                    <p className="service-highlight-description">
                        Harness the potential of the cloud for scalability, flexibility, and efficiency in your operations.
                    </p>
                </div>

                {/* Service 2: Enterprise Computing */}
                <div className="service-highlight-item">
                    <h3 className="service-highlight-heading">Enterprise Computing</h3>
                    <p className="service-highlight-description">
                        Optimize performance and reliability for mission-critical applications tailored for large-scale organizations.
                    </p>
                </div>

                {/* Service 3: Data Center Solutions */}
                <div className="service-highlight-item">
                    <h3 className="service-highlight-heading">Data Center Solutions</h3>
                    <p className="service-highlight-description">
                        Experience state-of-the-art solutions for optimal performance, resilience, and energy efficiency in data management.
                    </p>
                </div>

                {/* Service 4: Security Solutions */}
                <div className="service-highlight-item">
                    <h3 className="service-highlight-heading">Security Solutions</h3>
                    <p className="service-highlight-description">
                        Protect your digital assets with robust and tailored solutions designed to combat modern security threats.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default ServiceHighlights;