import React, { useEffect, useState } from 'react';
import '../styles/NetworkingSolutions.css'
import networkingSolutionsImage from '../assets/networking_solutions.jpg';

const NetworkingSolutions = () => {
    const [isLandscape, setIsLandscape] = useState(window.matchMedia("(orientation: landscape)").matches);
    const [isPortrait, setIsPortrait] = useState(window.matchMedia("(orientation: portrait)").matches);

    useEffect(() => {
        const handleOrientationChange = () => {
            setIsLandscape(window.matchMedia("(orientation: landscape)").matches);
            setIsPortrait(window.matchMedia("(orientation: portrait)").matches);
        };

        window.addEventListener("orientationchange", handleOrientationChange);

        return () => {
            window.removeEventListener("orientationchange", handleOrientationChange);
        };
    }, []);

    return (
        <div className="network-solutions-container">
            {isLandscape && <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ flex: '0.5', marginRight: '20px' }}>
                    <h2>Networking Solutions</h2>
                    <h3>Overview</h3>
                    <p>In today's digitally driven world, a robust and reliable network infrastructure is no longer optional—it's imperative. Networking Solutions is at the forefront of designing, deploying, and managing advanced connectivity solutions that cater to the current and future needs of businesses. We ensure that your enterprise is seamlessly connected, secure, and ready to face the challenges of the modern digital era.</p>
                </div>

                <div style={{ flex: '0.45' }}>
                    <img src={networkingSolutionsImage} alt="Networking Solutions" style={{ maxWidth: '100%', height: 'auto' }} />
                </div>
            </div>}

            {isPortrait && <div>
                <div style={{ flex: '0.5', marginRight: '20px' }}>
                    <h2>Networking Solutions</h2>
                    <h3>Overview</h3>
                    <p>In today's digitally driven world, a robust and reliable network infrastructure is no longer optional—it's imperative. Networking Solutions is at the forefront of designing, deploying, and managing advanced connectivity solutions that cater to the current and future needs of businesses. We ensure that your enterprise is seamlessly connected, secure, and ready to face the challenges of the modern digital era.</p>
                </div>

                <div style={{ flex: '0.45' }}>
                    <img src={networkingSolutionsImage} alt="Networking Solutions" style={{ maxWidth: '100%', height: 'auto' }} />
                </div>
            </div>}

            <h3>Why Choose Our Networking Solutions</h3>
            <ul>
                <li><strong>Advanced Connectivity:</strong> Our state-of-the-art solutions ensure that your business is connected 24/7 without any downtime.</li>
                <li><strong>Scalability:</strong> As your business grows, your network requirements will evolve. Our solutions are designed to scale with your needs.</li>
                <li><strong>Security:</strong> With rising cyber threats, network security has never been more critical. Our team prioritizes your network's security, ensuring that your data and assets are protected at all times.</li>
                <li><strong>Tailored Solutions:</strong> No two businesses are the same, which is why we offer customized networking solutions tailored to your specific needs and industry requirements.</li>
                <li><strong>Expert Support:</strong> Our team of certified professionals is available round-the-clock to assist you with any network-related queries or issues.</li>
            </ul>

            <h3>Our Offerings</h3>
            <ul>
                <li><strong>Enterprise Networking:</strong> We offer advanced enterprise networking solutions that ensure seamless connectivity across all branches and divisions of your business.</li>
                <li><strong>Wireless Solutions:</strong> Stay connected on the go with our top-notch wireless networking solutions, designed for optimal speed and coverage.</li>
                <li><strong>Data Center Networking:</strong> Ensure the smooth functioning of your data centers with our specialized networking solutions that guarantee high performance and uptime.</li>
                <li><strong>Network Security:</strong> Protect your enterprise from external and internal threats with our comprehensive network security solutions.</li>
                <li><strong>SD-WAN:</strong> Improve application performance and reduce costs with our Software-Defined Wide Area Networking solutions.</li>
                <li><strong>Unified Communications:</strong> Enhance collaboration and productivity within your organization with our Unified Communications solutions.</li>
            </ul>

            {/* <h3>Testimonials</h3>
            <blockquote>
                "Since we partnered with Networking Solutions, our organizational connectivity has improved significantly, and network downtimes have become a thing of the past." - <cite>John Doe, Tech Corp.</cite>
            </blockquote>
            <blockquote>
                "The team at Networking Solutions is knowledgeable, efficient, and always ready to help. Their tailored solutions have been a game-changer for our business." - <cite>Jane Smith, Innovate Inc.</cite>
            </blockquote> */}

            <h3>Get in Touch</h3>
            <p>Looking to enhance your business connectivity? Reach out to us today and discover how our Networking Solutions can transform your enterprise.</p>
            <button>Contact Us</button>

        </div>
    );
}

export default NetworkingSolutions;
