import React from 'react';

import '../styles/Culture.css'

const Culture = () => {
    return (
        <div className="culture-container">

            <h2>PhiGrid Culture: Pioneering a Progressive Work Ethos</h2>

            <h3>Our Beliefs</h3>
            <p>
                At PhiGrid, we've cultivated a culture that champions innovation, collaboration, and personal growth. We don't just build transformative IT solutions — we nurture minds, foster creativity, and create a haven for forward-thinkers.
            </p>

            <h3>Diversity & Inclusion</h3>
            <p>
                Every voice at PhiGrid is unique, and every voice is heard. We believe in harnessing the power of diverse perspectives, ensuring an inclusive environment where everyone feels valued, respected, and empowered.
            </p>

            <h3>Continuous Learning</h3>
            <p>
                In an ever-evolving tech landscape, staying stagnant isn't an option. At PhiGrid, we promote a culture of continuous learning, ensuring that our team always remains at the cutting edge of technology.
            </p>

            <h3>Work-Life Harmony</h3>
            <p>
                We understand the essence of a balanced life. While we ardently pursue our projects, we also advocate for the importance of personal time, well-being, and mental health. Work isn't just about tasks and deadlines; it's about people and their holistic development.
            </p>

            <h3>Community Engagement</h3>
            <p>
                PhiGrid isn't just a business; it's a responsible community member. We actively engage in various community initiatives, outreach programs, and environmental efforts, ensuring we give back to the world that has given us so much.
            </p>

            <h3>Celebrating Milestones</h3>
            <p>
                From individual achievements to company-wide successes, every milestone at PhiGrid is celebrated with vigor. Our events, team outings, and get-togethers are a testament to our tight-knit community spirit.
            </p>

            <h3>Join the PhiGrid Family</h3>
            <p>
                At PhiGrid, you're more than just an employee; you're family. Here, your ideas are nurtured, your passions are fueled, and your growth is ensured. Be a part of a culture that truly values you.
            </p>

            <button>Discover Life at PhiGrid</button>

        </div>
    );
}

export default Culture;
