import React from 'react';
import '../styles/AboutComponent.css';

const About = () => {
    return (
        <h3 className="about-us-text">
            At PhiGrid, we believe in the transformative power of technology and strive to harness it in ways that create tangible value for our clients. Our passion for innovation, coupled with deep industry knowledge, positions us as not just service providers, but as partners in our clients' journey towards digital transformation.
        </h3>
    );
}

export default About;