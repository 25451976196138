import React, { useEffect, useState } from 'react';
import '../styles/SecuritySolutions.css'
import securitySolutionsImage from '../assets/security_solutions.jpg';

const SecuritySolutions = () => {
    const [isLandscape, setIsLandscape] = useState(window.matchMedia("(orientation: landscape)").matches);
    const [isPortrait, setIsPortrait] = useState(window.matchMedia("(orientation: portrait)").matches);

    useEffect(() => {
        const handleOrientationChange = () => {
            setIsLandscape(window.matchMedia("(orientation: landscape)").matches);
            setIsPortrait(window.matchMedia("(orientation: portrait)").matches);
        };

        window.addEventListener("orientationchange", handleOrientationChange);

        return () => {
            window.removeEventListener("orientationchange", handleOrientationChange);
        };
    }, []);

    return (
        <div className="security-solutions-container">
            {isLandscape && <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ flex: '0.5', marginRight: '20px' }}>
                    <h2>Security Solutions</h2>
                    <h3>Overview</h3>
                    <p>In this digital age, securing your assets, data, and infrastructure has become paramount. Cyber threats evolve daily, and their potential impact can be catastrophic. At PhiGrid, we prioritize your security, offering tailored solutions that safeguard every facet of your digital presence, ensuring peace of mind.</p>
                </div>

                <div style={{ flex: '0.45' }}>
                    <img src={securitySolutionsImage} alt="Security Solutions" style={{ maxWidth: '100%', height: 'auto' }} />
                </div>
            </div>}

            {isPortrait && <div>
                <div style={{ flex: '0.5', marginRight: '20px' }}>
                    <h2>Security Solutions</h2>
                    <h3>Overview</h3>
                    <p>In this digital age, securing your assets, data, and infrastructure has become paramount. Cyber threats evolve daily, and their potential impact can be catastrophic. At PhiGrid, we prioritize your security, offering tailored solutions that safeguard every facet of your digital presence, ensuring peace of mind.</p>
                </div>

                <div style={{ flex: '0.45' }}>
                    <img src={securitySolutionsImage} alt="Security Solutions" style={{ maxWidth: '100%', height: 'auto' }} />
                </div>
            </div>}

            <h3>Why Choose PhiGrid's Security Solutions</h3>
            <ul>
                <li><strong>Comprehensive Protection:</strong> We don't just focus on one aspect of security. Our solutions are all-encompassing, shielding you from a broad range of threats.</li>
                <li><strong>Proactive Approach:</strong> Before threats become attacks, our systems detect and neutralize them, keeping you always one step ahead.</li>
                <li><strong>Customized Solutions:</strong> Not every business is the same. We craft security solutions that fit your unique needs.</li>
                <li><strong>24/7 Monitoring:</strong> Security doesn't sleep, and neither do we. Our dedicated teams work around the clock, ensuring uninterrupted protection.</li>
                <li><strong>User Training:</strong> Often, the human element is the weakest security link. We offer training to ensure your team is well-informed and vigilant.</li>
            </ul>

            <h3>Our Security Offerings</h3>
            <ul>
                <li><strong>Firewall & Intrusion Prevention:</strong> Safeguard your network from malicious threats and unwanted intrusions.</li>
                <li><strong>Endpoint Security:</strong> Ensure every device connected to your network is secure and compliant.</li>
                <li><strong>Data Protection & Encryption:</strong> Protect sensitive data, both at rest and in transit.</li>
                <li><strong>Identity & Access Management:</strong> Ensure only authorized individuals can access your systems.</li>
                <li><strong>Security Assessment & Consultation:</strong> Evaluate your current security posture and receive expert guidance on improvement areas.</li>
            </ul>

            {/* <h3>Client Testimonials</h3>
            <blockquote>
                "PhiGrid's security solutions have been a game-changer for us. We can now focus on our business, knowing our digital assets are secure." - <cite>Jane Smith, TechFusion Corp.</cite>
            </blockquote>
            <blockquote>
                "The peace of mind PhiGrid's 24/7 monitoring offers is invaluable. Their proactive approach has saved us from potential disasters more than once." - <cite>Carlos Mendoza, Innovations Ltd.</cite>
            </blockquote> */}

            <h3>Secure Your Future with PhiGrid</h3>
            <p>Don't wait for a breach to consider security. Get in touch with our experts and fortify your defenses today.</p>
            <button>Contact Us</button>

        </div>
    );
}

export default SecuritySolutions;