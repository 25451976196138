import React from 'react';
import { AiOutlinePhone, AiOutlineMail } from 'react-icons/ai';
import { FaWhatsapp, FaInstagram, FaFacebookF, FaTwitter } from 'react-icons/fa';
import '../styles/Footer.css';

const Footer = () => {
    return (
        <div className="footer-container">
            <div className="inner-container">

                <div className="contact-info">
                    <h3>Contact Us</h3>
                    <p><AiOutlinePhone style={{ marginRight: '10px' }} /> +91 9449523754</p>
                    <p><AiOutlineMail style={{ marginRight: '10px' }} /> sales@phigrid.in</p>
                </div>

                <div className="contact-info">
                    <h3>Address</h3>
                    <p>No: 398, "Sudha"</p>
                    <p>8th Main, NGEF Layout</p>
                    <p>Sadananda Nagar</p>
                    <p>Bangalore - 560 038</p>
                </div>

                <div className="follow-us">
                    <h3>Follow Us</h3>
                    <a href="https://www.facebook.com/PhiGridConsulting" target="_blank" rel="noopener noreferrer"><FaFacebookF size={24} /></a>
                    <a href="https://twitter.com/phigrid" target="_blank" rel="noopener noreferrer"><FaTwitter size={24} /></a>
                    <a href="https://www.instagram.com/phigrid" target="_blank" rel="noopener noreferrer"><FaInstagram size={24} /></a>
                    <a href="https://wa.me/919449523754" target="_blank" rel="noopener noreferrer"><FaWhatsapp size={24} /></a>
                </div>

            </div>

            <div className="centered-text">
                <p>© {new Date().getFullYear()} PhiGrid. All rights reserved.</p>
            </div>
        </div>
    );
}

export default Footer;