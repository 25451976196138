import React, { useEffect, useState } from 'react';
import '../styles/EndUserComputing.css';
import endUserComputingImage from '../assets/end_user_computing.jpg';

const EndUserComputing = () => {
    const [isLandscape, setIsLandscape] = useState(window.matchMedia("(orientation: landscape)").matches);
    const [isPortrait, setIsPortrait] = useState(window.matchMedia("(orientation: portrait)").matches);

    useEffect(() => {
        const handleOrientationChange = () => {
            setIsLandscape(window.matchMedia("(orientation: landscape)").matches);
            setIsPortrait(window.matchMedia("(orientation: portrait)").matches);
        };

        window.addEventListener("orientationchange", handleOrientationChange);

        return () => {
            window.removeEventListener("orientationchange", handleOrientationChange);
        };
    }, []);

    return (
        <div className="end-user-computing-container">
            {isLandscape && <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ flex: '0.5', marginRight: '20px' }}>
                    <h2>End User Computing Solutions</h2>
                    <h3>Overview</h3>
                    <p>In the evolving landscape of digital workplaces, End User Computing (EUC) plays a pivotal role in promoting productivity, agility, and a superior user experience. EUC solutions at PhiGrid are designed to provide employees with the tools and accessibility they need, regardless of location or device, without compromising security or corporate compliance.</p>
                </div>

                <div className="end-user-computing-image" style={{ flex: '0.45' }}>
                    <img src={endUserComputingImage} alt="End User Computing" style={{ maxWidth: '100%', height: 'auto' }} />
                </div>
            </div>}

            {isPortrait && <div>
                <div style={{ flex: '0.5', marginRight: '20px' }}>
                    <h2>End User Computing Solutions</h2>
                    <h3>Overview</h3>
                    <p>In the evolving landscape of digital workplaces, End User Computing (EUC) plays a pivotal role in promoting productivity, agility, and a superior user experience. EUC solutions at PhiGrid are designed to provide employees with the tools and accessibility they need, regardless of location or device, without compromising security or corporate compliance.</p>
                </div>

                <div className="end-user-computing-image" style={{ flex: '0.45' }}>
                    <img src={endUserComputingImage} alt="End User Computing" style={{ maxWidth: '100%', height: 'auto' }} />
                </div>
            </div>}
            
            <h3>Key Benefits</h3>
            <ul>
                <li><strong>Flexibility:</strong> Empower your workforce to access their applications and data from anywhere, on any device, fostering a truly mobile and responsive work environment.</li>
                <li><strong>Enhanced Productivity:</strong> With a seamless user experience, employees can focus on tasks without technological barriers, leading to improved efficiency and performance.</li>
                <li><strong>Cost Efficiency:</strong> Reduce costs associated with maintaining traditional IT infrastructures. EUC solutions often mean decreased dependency on hardware and increased reliance on the cloud.</li>
                <li><strong>Security & Compliance:</strong> Despite the flexibility and mobility, our solutions ensure that corporate data remains secure, and compliance standards are met, irrespective of the device or location from where it's accessed.</li>
                <li><strong>Centralized Management:</strong> IT departments can manage, update, and troubleshoot end-user systems centrally, leading to reduced downtimes and increased operational efficiency.</li>
            </ul>

            <h3>Our Offerings</h3>
            <ul>
                <li><strong>Desktop Virtualization:</strong> Deliver desktop services from your data center or cloud, ensuring employees can access their work environment from anywhere.</li>
                <li><strong>Mobile Device Management (MDM):</strong> Manage and secure employee mobile devices to protect corporate data and ensure compliance with corporate policies.</li>
                <li><strong>Unified Communication Tools:</strong> Streamline communication with tools that integrate chat, email, voice, and video capabilities, promoting collaboration and efficient communication.</li>
                <li><strong>Application Virtualization:</strong> Deploy applications centrally and provide access to employees based on roles and requirements, ensuring security and ease of management.</li>
                <li><strong>Workspace Aggregation:</strong> Provide employees with a unified workspace, integrating applications, desktops, and data into a single, easy-to-access platform.</li>
            </ul>

            <h3>Ready to Enhance Your Work Environment?</h3>
            <p>Speak to our EUC experts today and discover the perfect solution for your business's unique requirements.</p>
            <button>Contact Us</button>
        </div>
    );
}

export default EndUserComputing;
