import React from 'react';

import '../styles/Careers.css'

const Careers = () => {
    return (
        <div className="careers-container">

            <h2>Careers at PhiGrid</h2>

            <h3>Join Our Journey</h3>
            <p>
                At PhiGrid, we believe that every individual can make an impact. Our journey is fueled by innovation, dedication, and the expertise of our team. As we stride towards a technologically advanced future, we're always on the lookout for passionate individuals who are eager to make a difference.
            </p>

            <h3>Why Choose PhiGrid?</h3>
            <ul>
                <li><strong>Innovative Environment:</strong> We thrive in an atmosphere of innovation. Our projects are at the forefront of technology, offering you challenging and rewarding roles.</li>
                <li><strong>Career Growth:</strong> With a wide array of projects and domains, the opportunities for professional growth at PhiGrid are unparalleled.</li>
                <li><strong>Collaborative Culture:</strong> We believe in teamwork. At PhiGrid, you're a part of a supportive community that values collaborative efforts.</li>
                <li><strong>Learning & Development:</strong> Your personal growth is essential for us. We offer continuous learning opportunities, from workshops to certifications.</li>
                <li><strong>Work-Life Balance:</strong> While we're passionate about our work, we understand the importance of personal time. We promote a healthy work-life balance to ensure our team's well-being.</li>
            </ul>

            <h3>Current Openings</h3>
            <ul>
                <li><strong>Sales Manager:</strong> Drive our growth by building strong customer relationships and leading our sales initiatives.</li>
                <li><strong>Admin Manager:</strong> Ensure smooth operations by coordinating resources, streamlining processes, and managing office logistics.</li>
                <li><strong>Network Specialist:</strong> Be the backbone of our IT infrastructure.</li>
                <li><strong>Digital Marketing Executive:</strong> Spread the word about PhiGrid and its solutions.</li>
            </ul>
            <button>View All Openings</button>

            <h3>Employee Testimonials</h3>
            <blockquote>
                "PhiGrid's supportive environment and innovative projects make every day exciting and fulfilling." - <cite>Kumar, Lead Network Specialist.</cite>
            </blockquote>

            <h3>Shape Tomorrow, Today</h3>
            <p>If you're ready to contribute to the future, make your mark, and be a part of a company that values your skills and passions, then PhiGrid is the place for you. Come, be a part of our story.</p>
            <button>Apply Now</button>

        </div>
    );
}

export default Careers;