import React, { useEffect, useState } from 'react';
import '../styles/CloudComputing.css';
import cloudComputing from '../assets/cloud_computing.png';

const CloudComputing = () => {
    const [isLandscape, setIsLandscape] = useState(window.matchMedia("(orientation: landscape)").matches);
    const [isPortrait, setIsPortrait] = useState(window.matchMedia("(orientation: portrait)").matches);

    useEffect(() => {
        const handleOrientationChange = () => {
            setIsLandscape(window.matchMedia("(orientation: landscape)").matches);
            setIsPortrait(window.matchMedia("(orientation: portrait)").matches);
        };

        window.addEventListener("orientationchange", handleOrientationChange);

        return () => {
            window.removeEventListener("orientationchange", handleOrientationChange);
        };
    }, []);

    return (
        <div className={`cloud-computing-container ${isLandscape ? 'landscape' : ''} ${isPortrait ? 'portrait' : ''}`}>
            {isLandscape && (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ flex: '0.5', marginRight: '20px' }}>
                        <h2>Cloud Computing</h2>
                        <h3>Overview</h3>
                        <p>In a world increasingly reliant on digital technology, Cloud Computing has emerged as a cornerstone of modern IT. By harnessing the power of the cloud, businesses can access, store, and process data on remote servers, ensuring agility, scalability, and flexibility. At PhiGrid, we understand the transformative potential of the cloud, and we're dedicated to providing state-of-the-art cloud solutions tailored to meet your specific business needs.</p>
                    </div>

                    <div className="cloud-image" style={{ flex: '0.45' }}>
                        <img src={cloudComputing} alt="Cloud Computing" style={{ maxWidth: '100%', height: 'auto' }} />
                    </div>
                </div>
            )}

            {isPortrait && (
                <div>
                    <div style={{ flex: '0.5', marginRight: '20px' }}>
                        <h2>Cloud Computing</h2>
                        <h3>Overview</h3>
                        <p>In a world increasingly reliant on digital technology, Cloud Computing has emerged as a cornerstone of modern IT. By harnessing the power of the cloud, businesses can access, store, and process data on remote servers, ensuring agility, scalability, and flexibility. At PhiGrid, we understand the transformative potential of the cloud, and we're dedicated to providing state-of-the-art cloud solutions tailored to meet your specific business needs.</p>
                    </div>

                    <div className="cloud-image" style={{ flex: '0.45' }}>
                        <img src={cloudComputing} alt="Cloud Computing" style={{ maxWidth: '100%', height: 'auto' }} />
                    </div>
                </div>
            )}

            <h3>Why Cloud Computing is Crucial for Modern Businesses</h3>
            <ul>
                <li><strong>Flexibility & Scalability:</strong> Adapt to changing business conditions. With cloud solutions, you can scale up or down based on your requirements, without significant capital expenditures.</li>
                <li><strong>Cost Efficiency:</strong> Eliminate the costs of purchasing and maintaining hardware. Pay only for the resources you consume.</li>
                <li><strong>Collaboration Efficiency:</strong> Enhance team collaboration with shared storage solutions and real-time data access.</li>
                <li><strong>Disaster Recovery:</strong> With cloud backup solutions, you can ensure that your data is secure and easily recoverable in case of unforeseen events.</li>
                <li><strong>Automatic Software Updates:</strong> Stay updated with the latest features and security patches without worrying about manual installations.</li>
            </ul>

            <h3>Our Cloud Computing Solutions</h3>
            <ul>
                <li><strong>Infrastructure as a Service (IaaS):</strong> Eliminate the need for physical hardware while ensuring high availability, scalability, and flexibility with our IaaS solutions.</li>
                <li><strong>Platform as a Service (PaaS):</strong> Develop, run, and manage applications without the complexity of building and maintaining the infrastructure.</li>
                <li><strong>Software as a Service (SaaS):</strong> Access software over the Internet on a subscription basis, ensuring you always have the latest features without the hassle of installations and updates.</li>
                <li><strong>Cloud Backup & Disaster Recovery:</strong> Safeguard your critical data and ensure business continuity with our cloud backup and disaster recovery solutions.</li>
                <li><strong>Cloud Analytics & Big Data:</strong> Harness the power of big data and gain insights to drive your business forward with our advanced analytics solutions in the cloud.</li>
                <li><strong>Hybrid Cloud Solutions:</strong> Integrate on-premises, private cloud, and third-party public cloud services for increased flexibility and optimization of existing infrastructures.</li>
            </ul>

            <h3>Client Testimonials</h3>
            <blockquote>
                "Transitioning to the cloud seemed daunting, but PhiGrid made the process seamless and efficient. We've experienced increased productivity and reduced costs since the shift."
            </blockquote>
            <blockquote>
                "PhiGrid's cloud solutions have given us the agility and flexibility we needed to stay competitive in our market. Their support team is also exceptional."
            </blockquote>

            <h3>Ready to Elevate Your Business with the Cloud?</h3>
            <p>Talk to our cloud experts today and discover the right cloud solutions for your business.</p>
            <button>Contact Us</button>

        </div>
    );
}

export default CloudComputing;