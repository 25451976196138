import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Navbar from './components/Navbar';

import Home from './pages/Home'
import CloudComputing from './pages/CloudComputing'
import EndUserComputing from './pages/EndUserComputing'
import NetworkingSolutions from './pages/NetworkingSolutions';
import SecuritySolutions from './pages/SecuritySolutions'
import EnterpriseComputing from './pages/EnterpriseComputing'
import DataCenter from './pages/DataCenter'
import PrintingImaging from './pages/PrintingImaging'
import Careers from './pages/Careers'
import Culture from './pages/Culture';
import Footer from './components/Footer';
import AboutUs from './pages/AboutUs';

const App = () => {
    return (
        <Router>
            <Navbar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<AboutUs />} />
                {/* <Route path="/solutions" element={<Solutions />} /> */}
                <Route path="/solutions/cloudcomputing" element={<CloudComputing />} />
                <Route path="/solutions/endusercomputing" element={<EndUserComputing />} />
                <Route path="/solutions/networking" element={<NetworkingSolutions />} />
                <Route path="/solutions/security" element={<SecuritySolutions />} />
                <Route path="/solutions/enterprisecomputing" element={<EnterpriseComputing />} />
                <Route path="/solutions/datacenter" element={<DataCenter />} />
                <Route path="/solutions/printingimaging" element={<PrintingImaging />} />
                <Route path="/careers" element={<Careers />} />
                <Route path="/culture" element={<Culture />} />
            </Routes>
            <Footer />
        </Router>
    );
}

export default App;
