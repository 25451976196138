import React from 'react';

import '../styles/AboutUs.css'

const AboutUs = () => {
    return (
        <div className="about-container">

            <h2>About PhiGrid</h2>

            <h3>Unparalleled Experience</h3>
            <p>
                With more than three decades of industry experience spanning diverse domains, we assist businesses in achieving significant outcomes. We specialize in implementing and modernizing IT infrastructure solutions, offering comprehensive end-to-end services that transform business operations and drive growth.
            </p>

            <h3>Our Driving Force</h3>
            <p>
                What fuels our drive is an unwavering passion for technology, paired with a mission to keep your business ahead of the competition. At PhiGrid, we are constantly evolving, adapting, and pioneering new ways to leverage technology for business success.
            </p>

            <h3>A Client-Centric Approach</h3>
            <p>
                We adopt a holistic and strategic approach in our interactions with clients. By empathizing with your perspective, we ensure a deep understanding of your operations, visions, and challenges. This client-centric mindset allows us to tailor solutions that are truly aligned with your goals and aspirations.
            </p>

            <h3>Innovation at Our Core</h3>
            <p>
                In a rapidly changing digital landscape, staying ahead means being at the forefront of innovation. At PhiGrid, we are committed to research and development, continuously exploring new technologies, methodologies, and approaches to deliver cutting-edge solutions to our clients.
            </p>

            <h3>Global Reach, Local Expertise</h3>
            <p>
                While we serve clients globally, we pride ourselves on understanding local markets, cultures, and regulations. This unique blend of global reach with local expertise ensures that we provide solutions that resonate on every scale.
            </p>

            <h3>Sustainability & Responsibility</h3>
            <p>
                Beyond technology, we are deeply committed to sustainability and social responsibility. As we shape the future of IT, we ensure our footprint is green, and our impact is positive, supporting communities and ensuring a brighter future for all.
            </p>

            <h3>Join Our Journey</h3>
            <p>
                As we continue our journey of growth and innovation, we invite you to be a part of the PhiGrid story. Together, let's shape the future of technology and create meaningful change in the world.
            </p>

        </div>
    );
}

export default AboutUs;