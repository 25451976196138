import React, { useEffect, useState } from 'react';
import '../styles/DataCenterSolutions.css'
import dataCenterImage from '../assets/datacenter.png';

const DataCenterSolutions = () => {
    const [isLandscape, setIsLandscape] = useState(window.matchMedia("(orientation: landscape)").matches);
    const [isPortrait, setIsPortrait] = useState(window.matchMedia("(orientation: portrait)").matches);

    useEffect(() => {
        const handleOrientationChange = () => {
            setIsLandscape(window.matchMedia("(orientation: landscape)").matches);
            setIsPortrait(window.matchMedia("(orientation: portrait)").matches);
        };

        window.addEventListener("orientationchange", handleOrientationChange);

        return () => {
            window.removeEventListener("orientationchange", handleOrientationChange);
        };
    }, []);

    return (
        <div className="data-center-solutions-container">
            {isLandscape && <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={{ flex: '0.5', marginRight: '20px' }}>
                    <h2>Data Center Solutions</h2>
                    <h3>Overview</h3>
                    <p>In today's digital-centric world, data centers act as the heart of organizations, pumping essential data and ensuring seamless operations. At PhiGrid, we provide state-of-the-art data center solutions designed to meet the demands of modern enterprises, ensuring high performance, resilience, and energy efficiency.</p>
                </div>

                <div style={{ flex: '0.45' }}>
                    <img src={dataCenterImage} alt="Data Center" style={{ maxWidth: '100%', height: 'auto' }} />
                </div>
            </div>}

            {isPortrait && <div>
                <div style={{ flex: '0.5', marginRight: '20px' }}>
                    <h2>Data Center Solutions</h2>
                    <h3>Overview</h3>
                    <p>In today's digital-centric world, data centers act as the heart of organizations, pumping essential data and ensuring seamless operations. At PhiGrid, we provide state-of-the-art data center solutions designed to meet the demands of modern enterprises, ensuring high performance, resilience, and energy efficiency.</p>
                </div>

                <div style={{ flex: '0.45' }}>
                    <img src={dataCenterImage} alt="Data Center" style={{ maxWidth: '100%', height: 'auto' }} />
                </div>
            </div>}

            <h3>Why Choose PhiGrid's Data Center Solutions?</h3>
            <ul>
                <li><strong>Reliability:</strong> With our solutions, experience reduced downtimes and ensure that your operations run seamlessly round the clock.</li>
                <li><strong>Scalability:</strong> Our data centers are designed to grow with your business. As your needs evolve, our infrastructure can scale accordingly without disrupting ongoing operations.</li>
                <li><strong>Energy Efficient:</strong> In an era of environmental consciousness, our data centers are optimized for energy efficiency, reducing costs and minimizing environmental impact.</li>
                <li><strong>Advanced Security:</strong> Safeguard your crucial data with state-of-the-art security measures, including firewalls, intrusion detection systems, and physical security measures.</li>
                <li><strong>Expert Support:</strong> Our team of experts is available 24/7 to provide support, ensuring smooth and uninterrupted operations.</li>
            </ul>

            <h3>Key Offerings</h3>
            <ul>
                <li><strong>Colocation Services:</strong> Safeguard your equipment in our facilities, benefiting from our infrastructure, power, cooling, and security systems.</li>
                <li><strong>Managed Hosting:</strong> Leave the heavy lifting to us. From server maintenance to application management, our team takes care of it all.</li>
                <li><strong>Disaster Recovery:</strong> Prepare for the unexpected. Our solutions ensure data safety and quick recovery in the event of unforeseen events.</li>
                <li><strong>Cloud Integration:</strong> Seamlessly integrate cloud solutions with your data center for flexibility and scalability.</li>
                <li><strong>Networking Solutions:</strong> Ensure high-speed and secure connections within and outside your data center.</li>
            </ul>

            {/* <h3>Client Testimonials</h3>
            <blockquote>
                "Transitioning to PhiGrid's data center solutions transformed our operational efficiency. We've experienced noticeable improvements in speed and reliability." - <cite>Diana Perez, NextGen Innovations.</cite>
            </blockquote>
            <blockquote>
                "The energy efficiency and robust security of PhiGrid's data centers are truly commendable. Their expert support team is always there when we need them." - <cite>Raj Mehta, TechGlobe Corp.</cite>
            </blockquote> */}

            <h3>Secure, Reliable, Efficient</h3>
            <p>Harness the power of modern infrastructure with PhiGrid's Data Center Solutions and propel your business into the future.</p>
            <button>Contact Us</button>

        </div>
    );
}

export default DataCenterSolutions;